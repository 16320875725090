


















































import {defineComponent, PropType} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import TableInput from '@/tasks/components/inputs/TableInput.vue';
import STextareaShort from '@/common/components/STextareaShort.vue';
import STextarea from '@/common/components/STextarea.vue';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default defineComponent({
  name: 'UCIrvineELN2V2QuantitativeObservations1',
  components: {STextarea, TableInput, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  props: {
    isMarking: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      inputs: {
        additionalObservations: '',
        concentrationTableRows: [
          {
            a: 'Solution',
            b: 'Concentration of solution being diluted (Mconc) (µM)',
            c: 'Standard Concentration (Mdil) (µM)',
            d: 'Flask Volume (Vdil) (mL)',
            e: 'Pipet Volume (Vconc) (mL)',
          },
          {
            a: '',
            b: '',
            c: '',
            d: '',
            e: '',
          },
        ],
        absorbanceTableRows: [
          {
            a: 'Dye Name',
            b: 'Concentration (µM)',
            c: 'λmax (nm)',
            d: 'Absorbance (at λmax)',
          },
          {
            a: '',
            b: '',
            c: '',
            d: '',
          },
        ],
      },
      concentrationColumns: [
        {
          label: 'Solution',
          component: STextareaShort,
          prop: 'a',
          width: '20%',
        },
        {
          label: 'Concentration of solution being diluted (Mconc) (µM)',
          component: STextareaShort,
          prop: 'b',
          width: '20%',
        },
        {
          label: 'Standard Concentration (Mdil) (µM)',
          component: STextareaShort,
          prop: 'c',
          width: '20%',
        },
        {
          label: 'Flask Volume (Vdil) (mL)',
          component: STextareaShort,
          prop: 'd',
          width: '20%',
        },
        {
          label: 'Pipet Volume (Vconc) (mL)',
          component: STextareaShort,
          prop: 'e',
          width: '20%',
        },
      ],
      absorbanceColumns: [
        {
          label: 'Dye Name',
          component: STextareaShort,
          prop: 'a',
          width: '25%',
        },
        {
          label: 'Concentration (µM)',
          component: STextareaShort,
          prop: 'b',
          width: '25%',
        },
        {
          label: 'λmax (nm)',
          component: STextareaShort,
          prop: 'c',
          width: '25%',
        },
        {
          label: 'Absorbance (at λmax)',
          component: STextareaShort,
          prop: 'd',
          width: '25%',
        },
      ],
      questions: [
        {
          text: 'In the space below, please insert all remaining quantitative observations and calculations for the experiment.',
          inputName: 'additionalObservations',
        },
      ],
    };
  },
  methods: {},
});
